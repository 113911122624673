import React, { useEffect, useState } from 'react';
import {
  Button,
  InputAdornment,
  Select,
  Grid,
  MenuItem,
  FormControl,
  Input,
  Typography,
  CircularProgress,
  IconButton
} from '@mui/material';

import ReactGA from 'react-ga';
import SearchIcon from '@mui/icons-material/Search';
import { RangeFilter } from './RangeFilter.js';
import { styled } from '@mui/system';

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  backgroundColor: 'white',
  width: '100%'
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  color: 'black',
  '&:hover, &.Mui-focusVisible': {
    backgroundColor: theme.palette.primary.light
  }
}));

export const Filters = ({
  places,
  placeNames,
  selectedPlaces,
  setSelectedPlaces,
  placesChange,
  locations,
  selectedLocations,
  setSelectedLocations,
  makeList,
  selectedMakes,
  setSelectedMakes,
  registrationClass,
  selectedRegistrationClass,
  setSelectedRegistrationClass,
  fuels,
  selectedFuels,
  setSelectedFuels,
  wheelDrives,
  selectedWheelDrives,
  setSelectedWheelDrives,
  transmissions,
  setSelectedTransmissions,
  selectedTransmissions,
  equipments,
  selectedEquipments,
  setSelectedEquipments,
  priceRange,
  setPriceRange,
  yearRange,
  setYearRange,
  kmRange,
  setKmRange,
  sorting,
  setSorting,
  filteredCars,
  resetFilters,
  priceRangeSetting,
  kmRangeSetting,
  yearRangeSetting,
  dealerName,
  textSearch,
  setTextSearch,
  registrationClassChanged,
  drivingWheelChanged,
  equipmentChanged
}) => {
  const [sortingOptions, setSortingOptions] = useState([
    {
      label: 'Publisert',
      value: 'modifiedDate'
    },
    {
      value: 'price',
      label: 'Pris lav/høy'
    },
    {
      value: 'price-',
      label: 'Pris høy/lav'
    },
    {
      value: 'yearModel-',
      label: 'År nyest/eldst'
    },
    {
      value: 'yearModel',
      label: 'År eldst/nyest'
    }
  ]);
  const [searchText, setSearchText] = useState('');

  const textSearchChange = (event) => {
    // setTextSearch(event.target.value);
    setSearchText(event.target.value);
  };
  const doSearch = () => {
    setTextSearch(searchText);
  };

  useEffect(() => {
    setSearchText(textSearch);
  }, [textSearch]);

  useEffect(() => {
    if (dealerName) {
      document.title = dealerName;
      ReactGA.pageview(window.location.pathname + window.location.search, { title: dealerName });
    }
  }, [dealerName]);
  useEffect(() => {
    if (locations.indexOf('Høylandet Auto AS, avdeling Høylandet') >= 0) {
      setSortingOptions((sortingOptions) => [
        ...sortingOptions,
        {
          value: 'numOfSeats-',
          label: 'Antall seter mange/få'
        },
        {
          value: 'numOfSeats',
          label: 'Antall seter få/mange'
        }
      ]);
    }
  }, [locations]);

  return (
    <>
      <Grid container spacing={1} style={{ width: '100%', margin: 'unset' }}>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={3}>
          <FormControl style={{ width: '100%', background: 'white' }}>
            <Input
              id='textSearch'
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  doSearch();
                }
              }}
              placeholder='Søk biler...'
              value={searchText}
              onChange={textSearchChange}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={1} style={{ width: '100%', margin: 'unset' }}>
        {places.length > 1 && (
          <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
            <SelectOptions
              title='Geografisk område'
              defaultLabel='Alle områder...'
              options={placeNames}
              selected={selectedPlaces}
              setSelected={setSelectedPlaces}
              placesChange={placesChange}
            />
          </Grid>
        )}
        {locations && locations.length > 1 && (
          <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
            <SelectOptions
              title='Avdeling'
              defaultLabel='Alle avdelinger...'
              options={locations}
              selected={selectedLocations}
              setSelected={setSelectedLocations}
            />
          </Grid>
        )}

        <Grid item xs={12} sm={6} md={3} lg={3} xl={3} rowSpacing={'3px'} columnSpacing={'3px'}>
          <SelectOptions
            title='Bilmerke'
            defaultLabel='Alle bilmerker...'
            options={makeList}
            selected={selectedMakes}
            setSelected={setSelectedMakes}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
          <SelectOptions
            title='Biltype'
            defaultLabel='Alle biltyper...'
            options={registrationClass}
            selected={selectedRegistrationClass}
            setSelected={setSelectedRegistrationClass}
            registrationClassChanged={registrationClassChanged}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} style={{ width: '100%', margin: 'unset' }}>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
          <SelectOptions
            title='Drivstoff'
            defaultLabel='Alle drivstoff...'
            options={fuels}
            selected={selectedFuels}
            setSelected={setSelectedFuels}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
          <SelectOptions
            title='Hjuldrift'
            defaultLabel='Alle hjuldrift...'
            options={wheelDrives}
            selected={selectedWheelDrives}
            setSelected={setSelectedWheelDrives}
            drivingWheelChanged={drivingWheelChanged}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
          <SelectOptions
            title='Girkasse'
            defaultLabel='Alle girkasser...'
            options={transmissions}
            selected={selectedTransmissions}
            setSelected={setSelectedTransmissions}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
          <SelectOptions
            multiple={locations.some((element) => element.includes('Auto 8')) ? false : true}
            title='Utstyr'
            defaultLabel='Alt utstyr...'
            options={equipments}
            selected={selectedEquipments}
            setSelected={setSelectedEquipments}
            equipmentChanged={equipmentChanged}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} style={{ width: '100%', margin: 'unset' }}>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
          <RangeFilter
            settings={priceRangeSetting}
            current={priceRange}
            setCurrent={setPriceRange}
            step={10000}
            label='Pris'
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
          <RangeFilter
            settings={yearRangeSetting}
            current={yearRange}
            setCurrent={setYearRange}
            step={1}
            label='Årsmodell'
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
          <RangeFilter
            settings={kmRangeSetting}
            current={kmRange}
            setCurrent={setKmRange}
            step={10000}
            label='Km.stand'
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} style={{ marginBottom: '50px', width: '100%', margin: 'unset' }}>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
          <SelectOptions
            title='Sortér på'
            defaultLabel='Publisert'
            defaultValue='modifiedDate'
            options={sortingOptions}
            selected={sorting}
            setSelected={setSorting}
            multiple={false}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}></Grid>
        <Grid
          xs={6}
          sm={6}
          md={3}
          lg={3}
          xl={3}
          container
          item
          display='flex'
          alignItems='center'
          justifyContent='center'
        >
          <Typography variant='h6' color='primary'>
            Antall biler:
          </Typography>
          <Typography variant='h6' color='primary' style={{ fontWeight: 'bold', marginLeft: '10px' }}>
            {filteredCars ? filteredCars.length : <CircularProgress size={16} />}
          </Typography>
        </Grid>
        <Grid
          xs={6}
          sm={6}
          md={3}
          lg={3}
          xl={3}
          container
          item
          display='flex'
          alignItems='center'
          justifyContent='flex-end'
        >
          <Button
            variant='contained'
            color='primary'
            disableElevation
            // className={classes.button}
            onClick={resetFilters}
            style={{
              marginRight: '20px',
              borderRadius: '30px',
              padding: '15px 40px',
              whiteSpace: 'nowrap',
              transition: 'background-color 2s, color 2s'
            }}
          >
            Nullstill filter
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

const SelectOptions = ({
  title,
  defaultLabel,
  defaultValue,
  options,
  selected,
  setSelected,
  multiple,
  placesChange,
  registrationClassChanged,
  drivingWheelChanged,
  equipmentChanged
}) => {
  const [open, setOpen] = useState(false);
  const handleChange = (event) => {
    const selected =
      !event.target.value.length || event.target.value[event.target.value.length - 1] === 'all'
        ? ['all']
        : typeof event.target.value === 'string'
        ? event.target.value
        : event.target.value.filter((make) => make !== 'all');
    setSelected(selected);

    ReactGA.event({
      category: 'Filter',
      action: event.target.name,
      label: JSON.stringify(selected)
    });

    if (registrationClassChanged) {
      registrationClassChanged(selected);
    }
    if (drivingWheelChanged) {
      drivingWheelChanged(selected);
    }
    if (equipmentChanged) {
      equipmentChanged(selected);
    }

    if (placesChange) {
      placesChange(selected);
    }
  };
  const onClick = () => {
    setOpen(!open);
  };

  return (
    <div>
      <Typography variant='h6' color='primary' style={{ fontWeight: 'bold' }}>
        {title}
      </Typography>
      <StyledFormControl>
        <Select
          // MenuProps={{
          //   getContentAnchorEl: () => null
          // }}
          // MenuProps={{
          //   getcontentanchorel: () => null
          // }}
          name={title}
          onClick={onClick}
          disableUnderline
          defaultValue={defaultValue || 'all'}
          width='100%'
          id={defaultLabel.replaceAll('.', '').toLowerCase().split(' ').join('_')}
          multiple={multiple === undefined ? true : multiple}
          // multiple={true}
          open={open}
          value={selected}
          onChange={handleChange}
          input={<Input />}
          // classes={{ select: classes.form_select }}
        >
          {!defaultValue && (
            // <MenuItem key='all' value='all' classes={{ root: classes.root, selected: classes.selected }}>
            <StyledMenuItem key='all' value='all'>
              {defaultLabel}
            </StyledMenuItem>
          )}
          {options
            .sort((a, b) => (a > b ? 1 : -1))
            .map((option) => (
              <StyledMenuItem
                key={option.value || option}
                value={option.value || option}
                // classes={{ root: classes.root, selected: classes.selected }}
              >
                {option.label || option}
              </StyledMenuItem>
            ))}
        </Select>
      </StyledFormControl>
    </div>
  );
};
