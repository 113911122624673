import React, { useEffect, useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import './carousel.css';
import './showmore.css';
import { Carousel } from 'react-responsive-carousel';
import useMediaQuery from '@mui/material/useMediaQuery';
import ShowMoreText from 'react-show-more-text';
import ReactGA from 'react-ga';
import { useHistory } from 'react-router-dom';
// import Loan from 'loan';

import { Grid, Typography, Box, Button, useTheme, List, ListItem, ListItemText } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ReactComponent as CalendarTodayIcon } from '../../images/calendar.svg';
import { ReactComponent as LocalGasStationIcon } from '../../images/gas.svg';
import { ReactComponent as AvTimerIcon } from '../../images/gauge.svg';
import { ReactComponent as TransmissionIcon } from '../../images/transmission.svg';

import { Finance } from './Finance';
import { Spesifications } from './Spesification';
import { Contact } from './Contact';

export const Car = ({ match, setTheme, resetCar, topBar }) => {
  const { carId, dealerId, link } = match.params;

  const [finance, setFinance] = useState(false);
  const [loadCar, setLoadCar] = useState(true);
  const [car, setCar] = useState(null);
  const [images, setImages] = useState([]);
  const matches = useMediaQuery('(min-width:600px)');
  const history = useHistory();

  const [messageInterval, setMessageInterval] = useState(false);

  useEffect(() => {
    if (car && car.car.make) {
      const carTitle = car.car.make + ' ' + car.car.model + ' ' + car.car.title;
      document.title = carTitle;
      ReactGA.pageview(window.location.pathname + window.location.search, { title: carTitle });
    }
  }, [car]);

  useEffect(() => {
    return history.listen((location) => {
      resetCar(null);
    });
  }, [history, resetCar]);

  useEffect(() => {
    if (loadCar) {
      setLoadCar(false);
      fetch(process.env.REACT_APP_BACKEND_URL + '/car/' + dealerId + '/' + carId).then((res) => {
        res.json().then((car) => {
          // setTheme({
          //   palette: {
          //     primary: {
          //       main: car.theme.primary?.main ? car.theme.primary.main : null,
          //       light: car.theme.primary?.light ? car.theme.primary.light : null
          //     },
          //     secondary: {
          //       main: car.theme.secondary?.main ? car.theme.secondary.main : null,
          //       light: car.theme.secondary?.light ? car.theme.secondary.light : null
          //     }
          //   }
          // });

          setImages([...car.car.images.sort((a, b) => (a.priority > b.priority ? 1 : -1))]);

          if (car.finance) {
            setFinance(car.finance);
          }
          setCar({ ...car });
        });
      });
    }
  }, [carId, loadCar, dealerId, setTheme]);

  const [swipeEnabled, setSwipeEnabled] = useState(true);
  const [touchEventsSet, setTouchEventsSet] = useState(false);
  const swipeScrollTolerance = 30;

  useEffect(() => {
    if (images.length) {
      const disableScroll = () => {
        let carousel = document.querySelector('.carousel');

        let startPos;
        let endPos;
        let isScrolling;
        let passedSwipeScrollTolerance = false;

        function touchMove(e) {
          if (e.targetTouches.length > 1 || (e.scale && e.scale !== 1) || !startPos) {
            return;
          }

          if (!passedSwipeScrollTolerance && swipeEnabled) {
            const touch = e.targetTouches[0];
            endPos = {
              x: Math.abs(touch.pageX - startPos.x),
              y: Math.abs(touch.pageY - startPos.y)
            };
            passedSwipeScrollTolerance = endPos.x > swipeScrollTolerance;

            isScrolling = endPos.x < endPos.y; //When isScrolling it means vertical sliding
            if (isScrolling) {
              setSwipeEnabled(false);
            }
          }
        }

        function touchEnd() {
          setSwipeEnabled(true);
          passedSwipeScrollTolerance = false;
          carousel.removeEventListener('touchmove', touchMove);
        }

        function touchStart(e) {
          const touch = e.targetTouches[0]; //The touches array object gets all the touches on the screen, taking the first touch
          startPos = {
            x: touch.pageX,
            y: touch.pageY
          };

          carousel.addEventListener('touchmove', touchMove);
        }

        if (!touchEventsSet) {
          carousel.addEventListener('touchstart', touchStart);
          carousel.addEventListener('touchend', touchEnd);
          setTouchEventsSet(true);
        }

        // setTouchEventsSet((touchEventsSet) => {
        //   if (!touchEventsSet) {
        //     carousel.addEventListener('touchstart', touchStart);
        //     carousel.addEventListener('touchend', touchEnd);
        //     return true;
        //   }
        //   return false;
        // });

        if (!swipeEnabled) {
          setSwipeEnabled(true);
          passedSwipeScrollTolerance = false;
        }
      };

      disableScroll();
    }
  }, [images, swipeEnabled, touchEventsSet]);

  useEffect(() => {
    if (window.self !== window.top && !messageInterval) {
      setMessageInterval(true);
      setInterval(() => {
        const message = {
          height: document.body.scrollHeight,
          width: document.body.scrollWidth - 50
          // params: { dealerId: dealerId, locationId: locationId, ...car }
        };
        window.top.postMessage(message, '*');
      }, 500);
    }
  }, [setMessageInterval, messageInterval]);

  return (
    <>
      {/* {!topBar && window.history.length > 1 && (
        <div
          style={{ width: '200px', cursor: 'pointer' }}
          onClick={() => {
            window.history.back();
          }}
        >
          <ArrowBackIcon /> <span style={{ position: 'absolute', lineHeight: '24px' }}>Til søket</span>
        </div>
      )} */}
      <Carousel
        swipeable={swipeEnabled}
        dynamicHeight
        emulateTouch
        renderIndicator={null}
        // renderIndicator={matches ? renderIndicator : null}
        showArrows
        showThumbs={matches ? true : false}
        showStatus={matches ? true : true}
        infiniteLoop
        interval={1000000}
        statusFormatter={(currentItem, total) => `${currentItem} av ${total}`}
        // preventMovementUntilSwipeScrollTolerance
        // swipeScrollTolerance={100}
      >
        {/* {images} */}
        {images.length &&
          images.map((image, index) => {
            return (
              <img
                alt={''}
                src={image.url}
                key={'img' + index}
                onError={(e) => {
                  console.log('err', index);
                  setImages([...images.filter((i) => i.priority !== image.priority)]);
                }}
              />
            );
          })}
      </Carousel>

      <Grid container spacing={3} style={{ width: '100%', margin: 'unset', backgroundColor: 'white' }}>
        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
          {car && <CarMain car={car.car} />}

          {car?.car?.equipments?.length > 0 && <Equipments equipments={car.car.equipments} />}
          {finance && car && <Spesifications car={car.car} small />}
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          {finance && (
            <Grid item xs={12} sm={7} md={12}>
              {car && <Finance car={car.car} finance={finance} />}
            </Grid>
          )}
          <Grid item xs={12} sm={5} md={12}>
            {car && (
              <Contact
                email={car.contacts?.length && car.contacts[0].email}
                name={car.contacts?.length && car.contacts[0].name}
                mobile={car.contacts?.length && car.contacts[0].mobile}
                phone={car.contacts?.length && car.contacts[0].phone}
                location={car && car.location}
                regNum={car.car.regNum}
                carid={car._id}
                carbuddii={car.carbuddii}
                car={car.car}
                avatarUrl={car.contacts?.length && car.contacts[0].avatarUrl}
              />
            )}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          {!finance && car && <Spesifications car={car.car} />}
        </Grid>
        <Grid item>{car && <Description car={car.car} dealerId={dealerId} />}</Grid>
      </Grid>
      {/* {link !== 'link' && window.history.length > 1 && (
        <div
          style={{ width: '200px', cursor: 'pointer' }}
          onClick={() => {
            window.history.back();
          }}
        >
          <ArrowBackIcon /> <span style={{ position: 'absolute', lineHeight: '24px' }}>Til søket</span>
        </div>
      )} */}
    </>
  );
};

const CarMain = ({ car }) => {
  const theme = useTheme();

  useEffect(() => {
    let once = true;
    window.parentIFrame &&
      window.parentIFrame.getPageInfo((pageInfo) => {
        once && window.parentIFrame.scrollToOffset(0, 0);
        once = false;
      });
  }, []);

  return (
    <Box style={{ padding: '70px 20px 50px' }}>
      {car.isSold && (
        <Button
          sx={{
            left: '10px',
            marginBottom: '-25px',
            marginTop: '-90px',
            marginLeft: '-10px',
            borderRadius: '20px',
            width: '100px',
            color: 'white',
            backgroundColor: theme.palette.secondary.light
          }}
        >
          Solgt
        </Button>
      )}
      <Typography color='primary' variant='h4' style={{ fontWeight: 'bold', lineHeight: '44px', marginBottom: '0' }}>
        {car.make} {car.model} <span style={{ fontSize: '22px' }}>{car.variant}</span>
      </Typography>
      <Typography variant='h5' style={{ marginBottom: '10px', overflow: 'hidden' }}>
        {car.title?.length > 1 && car.title}
      </Typography>
      Totalpris
      <Typography color='primary' variant='h3' style={{ fontWeight: 'bold', marginTop: '0' }}>
        <span style={{ fontSize: '20px' }}>Kr</span> {car.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')},-
      </Typography>
      <Grid container style={{ marginTop: '20px' }}>
        <CarIcon Icon={CalendarTodayIcon} label='Årsmodell' title={car.yearModel} />
        <CarIcon
          Icon={AvTimerIcon}
          label='Kilometer'
          title={car.mileage.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
        />
        <CarIcon Icon={TransmissionIcon} label='Girkasse' title={car.transmission} />
        <CarIcon Icon={LocalGasStationIcon} label='Drivstoff' title={car.fuel.replace('Strøm', 'Elektrisk')} />
      </Grid>
    </Box>
  );
};

const CarIcon = ({ Icon, title, label }) => {
  const theme = useTheme();

  return (
    <Grid xs={12} sm={6} md={3} item style={{ marginTop: '20px' }}>
      <Grid container wrap='nowrap'>
        <Grid item xs={5} sx={{ fill: theme.palette.primary.main, textAlign: 'center' }}>
          <Icon width='50px' />
        </Grid>
        <Grid>
          <Grid item xs={12}>
            <Typography>{label}</Typography>
          </Grid>
          <Grid item xs>
            <Typography color='primary' variant='h6' style={{ fontWeight: 'bold' }}>
              {title}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const Equipments = ({ equipments }) => {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up('sm'));
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const lg = useMediaQuery(theme.breakpoints.up('lg'));

  const [equipmentColumns, setEquipmentColumns] = useState([]);
  const [numRows, setNumRows] = useState(1);

  useEffect(() => {
    if (lg) setNumRows(4);
    else if (md) setNumRows(3);
    else if (sm) setNumRows(2);
    else setNumRows(1);
  }, [sm, md, lg]);

  useEffect(() => {
    equipments.sort((a, b) => (a.text > b.text ? 1 : -1));
    const copy = [...equipments];
    const equipmentColumns = [...Array(numRows).keys()].reverse().map((key) => {
      return copy.splice(0, Math.ceil(copy.length / (key + 1)));
    });

    setEquipmentColumns(equipmentColumns);
  }, [equipments, numRows]);

  return (
    <div style={{ padding: '0 20px' }}>
      <Typography color='primary' variant='h5' style={{ fontWeight: 'bold' }}>
        Utstyr
      </Typography>
      <Grid container spacing={0}>
        {equipmentColumns &&
          equipmentColumns.map((equipments, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              {/* xs=1, sm=2 md=3 */}
              <List dense={true} style={{ padding: 0 }}>
                {equipments &&
                  equipments.map((equipment) => (
                    <ListItem
                      key={equipment.text}
                      style={{
                        backgroundImage: 'url(/images/Bullet.svg)',
                        backgroundPosition: '0px 9px',
                        backgroundSize: '10px',
                        backgroundRepeat: 'no-repeat',
                        padding: '0 16px',
                        overflow: 'hidden'
                      }}
                    >
                      <ListItemText primary={equipment.text} />
                    </ListItem>
                  ))}
              </List>
            </Grid>
          ))}
      </Grid>
    </div>
  );
};

const Description = ({ car, dealerId }) => {
  const [showAll, setShowAll] = useState(false);
  const { description, warrantyKm, warrantyMonths, warrantyText } = car;

  let formattedDescription = description.replace(/\*\*_(.*?)_\*\*/g, '<b><i>$1</i></b>');
  formattedDescription = formattedDescription.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');

  formattedDescription = formattedDescription.replaceAll('*', '&bull;');

  return (
    <Grid item style={{ whiteSpace: 'pre-wrap', padding: '30px' }}>
      {
        /*dealerId === '610285855c8cf3574d4c90f6' &&*/ warrantyMonths || warrantyKm ? (
          <>
            <Typography color='primary' variant='h5' style={{ fontWeight: 'bold', margin: '20px 0 10px' }}>
              Garanti
            </Typography>
            <Typography
              style={{
                lineHeight: '24px'
              }}
            >
              Denne bilen selges med {warrantyText} ({warrantyMonths ? warrantyMonths + ' mnd' : ''}
              {warrantyMonths && warrantyKm ? ' / ' : ''}
              {warrantyKm ? warrantyKm.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' km' : ''}).
            </Typography>
          </>
        ) : null
      }

      <Typography color='primary' variant='h5' style={{ fontWeight: 'bold', margin: '20px 0 10px' }}>
        Bilen selges med 5 års reklamasjonsrett
      </Typography>
      <Typography
        style={{
          lineHeight: '22px'
        }}
      >
        Denne bilen selges av en forhandler og dette medfører at du som kjøper har 5 års reklamasjonsrett mot skjulte
        feil og mangler. Reklamasjonsretten kan brukes dersom bilen er i dårligere stand enn du som kjøper har grunn til
        å forvente, basert på bilens alder, kjørelengde og informasjon du har fått fra selger. Reklamasjonsretten
        gjelder fra overlevering av bilen.
      </Typography>
      <Typography color='primary' variant='h5' style={{ fontWeight: 'bold', margin: '20px 0 10px' }}>
        Beskrivelse
      </Typography>
      {showAll ? (
        <>
          <div
            dangerouslySetInnerHTML={{ __html: formattedDescription }}
            style={{
              lineHeight: '22px',
              fontWeight: 300,
              fontSize: '1rem',
              fontFamily: 'Roboto, Helvetica, Arial, sans-serif'
            }}
          />
          <Button
            onClick={() => {
              setShowAll(false);
            }}
            variant='contained'
            disableElevation
            sx={{
              borderRadius: '30px',
              padding: '10px 50px',
              '&:hover': {
                color: '#fff'
              }
            }}
            color='primary'
            style={{ marginTop: '10px', marginBottom: '20px', fontWeight: 'bold' }}
          >
            Vis mindre
          </Button>
        </>
      ) : (
        <ShowMoreText
          className='showmore'
          lines={8}
          keepNewLines={true}
          truncatedEndingComponent={''}
          more={
            <>
              <br />
              <Button
                onClick={() => {
                  setShowAll(true);
                }}
                variant='contained'
                disableElevation
                sx={{
                  borderRadius: '30px',
                  padding: '10px 50px',
                  '&:hover': {
                    color: '#fff'
                  }
                }}
                color='primary'
                style={{ marginTop: '10px', marginBottom: '20px', fontWeight: 'bold' }}
              >
                Vis hele beskrivelsen
              </Button>
            </>
          }
          less={<div>Vis mindre</div>}
        >
          {formattedDescription}
        </ShowMoreText>
      )}

      <Typography style={{ margin: '30px 0', fontWeight: 700 }}>
        Prospektet kan være mangelfult. Fullstendig salgsinformasjon og opplysninger iht. opplysningsplikt kan fås
        direkte fra selger.
      </Typography>
    </Grid>
  );
};
